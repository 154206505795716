import * as React from 'react';
import { cn } from '@/helpers/shadcn';

const Empty = React.forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div ref={ref} className={cn('flex min-h-[400px] flex-col items-center justify-center space-y-1 text-center', className)} {...props}>
      {children}
    </div>
  );
});
Empty.displayName = 'Empty';

const EmptyIcon = React.forwardRef(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn('rounded-full bg-muted p-3 border', className)} {...props} />;
});
EmptyIcon.displayName = 'EmptyIcon';

const EmptyTitle = React.forwardRef(({ className, ...props }, ref) => {
  return <h3 ref={ref} className={cn('text-lg font-medium pt-1', className)} {...props} />;
});
EmptyTitle.displayName = 'EmptyTitle';

const EmptyDescription = React.forwardRef(({ className, ...props }, ref) => {
  return <p ref={ref} className={cn('mb-4 mt-2 text-sm text-muted-foreground', className)} {...props} />;
});
EmptyDescription.displayName = 'EmptyDescription';

const EmptyActions = React.forwardRef(({ className, ...props }, ref) => {
  return <div ref={ref} className={cn('flex flex-col gap-2 min-w-[200px] pt-4', className)} {...props} />;
});
EmptyActions.displayName = 'EmptyActions';

export { Empty, EmptyIcon, EmptyTitle, EmptyDescription, EmptyActions };
