import { Link, Outlet, useHref, useParams } from 'react-router-dom';
import { Building2, Users, EllipsisVertical, PowerOff, Power } from 'lucide-react';
import { useState } from 'react';
import DisableCompanyDialog from './DisableCompanyDialog';
import EnableCompanyDialog from './EnableCompanyDialog';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbPage, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { useCompany } from '@/data/company';
import { Loading } from '@/components/ui/loading';
import { Error } from '@/components/ui/error';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
const Company = () => {
  const params = useParams();
  const company = useCompany(params.companyUid);
  const pathname = useHref();
  const [isDisableOpen, setIsDisableOpen] = useState(false);
  const [isEnableOpen, setIsEnableOpen] = useState(false);

  const handleOpenDisable = () => {
    setIsDisableOpen(true);
  };

  const handleOpenEnable = () => {
    setIsEnableOpen(true);
  };

  const handleCloseDisable = () => {
    setIsDisableOpen(false);
  };

  const handleCloseEnable = () => {
    setIsEnableOpen(false);
  };

  const handleDisableSuccess = async (updatedCompany) => {
    await company.mutate(() => updatedCompany, false);
  };

  const handleEnableSuccess = async (updatedCompany) => {
    await company.mutate(() => updatedCompany, false);
  };

  if (company.isLoading && !company.data) {
    return <Loading />;
  }

  if (company.error) {
    return <Error message="An error occurred loading company" />;
  }

  return (
    <>
      <Breadcrumb className="mb-4">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to="/company">Companies</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{company.data.company_name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <div className="flex-auto space-y-1.5">
              <CardTitle>{company.data.company_name}</CardTitle>
              <CardDescription>{company.data.company_description}</CardDescription>
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline" size="icon" aria-label="Open menu">
                  <EllipsisVertical />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuLabel>{company.data.company_name}</DropdownMenuLabel>
                <DropdownMenuSeparator />
                {company.data.disabled && (
                  <DropdownMenuItem onClick={handleOpenEnable}>
                    <Power />
                    Enable Company
                  </DropdownMenuItem>
                )}
                {!company.data.disabled && (
                  <DropdownMenuItem className="text-destructive" onClick={handleOpenDisable}>
                    <PowerOff />
                    Disable Company
                  </DropdownMenuItem>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </CardHeader>
        <CardContent>
          {company.data.disabled && (
            <Alert variant="destructive" className="mb-4">
              <PowerOff className="h-4 w-4" />
              <AlertTitle>Disabled</AlertTitle>
              <AlertDescription>This company is disabled. Users cannot login. </AlertDescription>
            </Alert>
          )}
          <Tabs value={pathname} className="w-full">
            <TabsList className="w-full">
              <TabsTrigger value={`/company/${params.companyUid}`} asChild>
                <Link to={`/company/${params.companyUid}`}>
                  <Building2 />
                  Company
                </Link>
              </TabsTrigger>
              <TabsTrigger value={`/company/${params.companyUid}/users`} asChild>
                <Link to={`/company/${params.companyUid}/users`}>
                  <Users />
                  Users
                </Link>
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <div className="pt-4">
            <Outlet />
          </div>
        </CardContent>
      </Card>
      {isDisableOpen && <DisableCompanyDialog onClose={handleCloseDisable} uid={params.companyUid} onSuccess={handleDisableSuccess} />}
      {isEnableOpen && <EnableCompanyDialog onClose={handleCloseEnable} uid={params.companyUid} onSuccess={handleEnableSuccess} />}
    </>
  );
};

export default Company;
