const getApiUrl = () => {
  if (window.location.hostname === 'admin.zebel.io') {
    return 'https://api.v2.zebel.io/v1';
  }

  if (window.location.hostname === 'admin.dev.zebel.io') {
    return 'https://api.v2.dev.zebel.io/v1';
  }

  return 'http://localhost:5001/v1';
};

const getCookieDomain = () => {
  if (window.location.origin.includes('localhost')) return 'localhost';
  if (window.location.origin.includes('admin.zebel.io')) return '.admin.zebel.io';
  if (window.location.origin.includes('admin.dev.zebel.io')) return '.admin.dev.zebel.io';
};

export { getCookieDomain, getApiUrl };
