import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { SearchIcon } from 'lucide-react';
import { searchSchema } from '../../_validators';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useDebouncedFormSubmit } from '@/helpers/form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const SearchForm = ({ placeholder = 'Search' }) => {
  const [searchParams] = useSearchParams();
  const form = useForm({ resolver: zodResolver(searchSchema), defaultValues: { q: searchParams.get('q') || '', disabled: searchParams.get('disabled') || 'false' } });
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = async (values) => {
    const searchParams = new URLSearchParams(location.search);

    if (values.q) {
      searchParams.set('q', values.q);
    } else {
      searchParams.delete('q');
    }

    if (values.disabled === 'true') {
      searchParams.set('disabled', 'true');
    } else {
      searchParams.delete('disabled');
    }

    const queryString = searchParams.toString();
    navigate(`${location.pathname}${queryString ? `?${queryString}` : ''}`);
  };

  useDebouncedFormSubmit(form, 'q', onSubmit, 250);
  useDebouncedFormSubmit(form, 'disabled', onSubmit, 0);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex items-center gap-2">
        <FormField
          control={form.control}
          name="q"
          startIcon={<SearchIcon />}
          render={({ field }) => (
            <FormItem className="min-w-[290px]">
              <FormLabel className="sr-only">Search</FormLabel>
              <FormControl>
                <Input type="search" placeholder={placeholder} autoFocus={!!form.watch('q')} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="disabled"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="sr-only">Status</FormLabel>
              <FormControl>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Active" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="false">Active</SelectItem>
                    <SelectItem value="true">Disabled</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" className="sr-only">
          Submit
        </Button>
      </form>
    </Form>
  );
};

export default SearchForm;
