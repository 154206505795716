import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Loader2, TriangleAlert } from 'lucide-react';
import { Error } from '@/components/ui/error';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { usePutCompany } from '@/data/company';

const formSchema = z.object({
  is_confirmed: z.boolean().refine((val) => val === true, {
    message: 'You must confirm this action by checking the box.'
  })
});

const DisableCompanyDialog = ({ onClose, uid, onSuccess }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const putCompany = usePutCompany();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      is_confirmed: false
    }
  });

  const onSubmit = async () => {
    setApiError('');
    setIsSubmitting(true);
    try {
      const updatedCompany = await putCompany(uid, { disabled: true });
      await onSuccess(updatedCompany);
      onClose();
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  return (
    <Dialog open onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Disable Company</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <Alert variant="destructive">
              <TriangleAlert className="h-4 w-4" />
              <AlertTitle>Warning</AlertTitle>
              <AlertDescription>Are you sure you want to disable this company? Users will no longer be able to login.</AlertDescription>
            </Alert>
            <FormField
              control={form.control}
              name="is_confirmed"
              render={({ field }) => (
                <FormItem className="">
                  <div className="flex items-center gap-2 space-y-0 ">
                    <FormControl className="flex items-center ">
                      <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                    </FormControl>
                    <FormLabel>I want to disable this company</FormLabel>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            {apiError && <Error message={apiError} />}
            <DialogFooter>
              <Button type="submit" disabled={isSubmitting} variant="destructive">
                {isSubmitting && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Disable Company
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default DisableCompanyDialog;
